<template>
  <div class="edit-activity-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Activity' }"
        >活動管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>編輯活動</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="activityForm"
        :model="activity_form"
        :rules="activity_rules"
        label-position="top"
      >
        <el-form-item label="活動照片" prop="photo">
          <el-upload
            class="photo-uploader"
            action=""
            :show-file-list="false"
            accept=".jpeg,.jpg,.png"
            :http-request="handleUpload"
            :before-upload="handleBeforeUpload"
          >
            <img
              v-if="preview_url"
              :src="preview_url"
              class="photo"
              alt="photo"
            />
            <i v-else class="el-icon-plus photo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="報名起始時間" prop="start_date">
          <el-date-picker
            v-model="activity_form.start_date"
            type="datetime"
            placeholder="選擇起始時間"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="報名結束時間" prop="end_date">
          <el-date-picker
            v-model="activity_form.end_date"
            type="datetime"
            placeholder="選擇結束時間"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活動場次時間" prop="activity_sessions">
          <template #label>
            <span>活動場次時間</span>&nbsp;
            <span style="font-size: 12px"
              >(此區排序會依照場次開始時間自行排序)</span
            >
          </template>
          <div
            v-for="(_, index) in activity_form.activity_sessions"
            :key="index"
            class="activity-sessions-block"
          >
            <div>場次{{ idx_array[index] }}</div>
            <div class="time-picker">
              <el-date-picker
                v-model="activity_form.activity_sessions[index].start_time"
                type="datetime"
                placeholder="選擇場次起始時間"
              >
              </el-date-picker>
              <el-date-picker
                v-model="activity_form.activity_sessions[index].end_time"
                type="datetime"
                placeholder="選擇場次結束時間"
              >
              </el-date-picker>
              <div>
                <el-button
                  label="新增"
                  type="primary"
                  size="mini"
                  circle
                  @click="handleAddActivitySession(index)"
                >
                  <i class="el-icon-plus"></i>
                </el-button>
                <el-button
                  icon="el-icon-minus"
                  label="移除"
                  type="danger"
                  size="mini"
                  circle
                  @click="handleDeleteActivitySession(index)"
                ></el-button>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="中文標題" prop="title_zh">
          <el-input
            v-model="activity_form.title_zh"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文標題" prop="title_en">
          <el-input
            v-model="activity_form.title_en"
            maxlength="178"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="中文介紹" prop="introduction_zh">
          <div ref="js-editor-introduction_zh" class="editor"></div>
        </el-form-item>
        <el-form-item label="英文介紹" prop="introduction_en">
          <div ref="js-editor-introduction_en" class="editor"></div>
        </el-form-item>
        <el-form-item label="中文地點" prop="location_zh">
          <el-input
            v-model="activity_form.location_zh"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文地點" prop="location_en">
          <el-input
            v-model="activity_form.location_en"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="活動類別" prop="category_id">
          <el-select v-model="activity_form.category_id">
            <el-option
              v-for="c in categories"
              :key="c.category_id"
              :value="c.category_id"
              :label="c.name_zh"
            ></el-option>
          </el-select>
          <el-select
            v-model="activity_form.subcategory_id"
            v-if="subcategories.length !== 0"
            style="margin-left: 16px"
          >
            <el-option
              v-for="sc in subcategories"
              :key="sc.subcategory_id"
              :value="sc.subcategory_id"
              :label="sc.name_zh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最大參與人數" prop="max_participant">
          <el-input v-model.number="activity_form.max_participant"></el-input>
        </el-form-item>
        <div v-for="number in [1, 2, 3]" :key="number">
          <el-form-item
            :label="`活動問題${number}`"
            :prop="`question_${number}`"
          >
            <div>
              <div style="margin-bottom: 12px">
                <span style="margin-right: 12px; color: #606266">是否必填</span>
                <el-radio-group
                  v-model="activity_form[`question_${number}`].required"
                  size="small"
                >
                  <el-radio :label="true" :border="true">是</el-radio>
                  <el-radio :label="false" :border="true">否</el-radio>
                </el-radio-group>
              </div>
              <div style="margin-bottom: 12px">
                <span style="margin-right: 12px; color: #606266">類型</span>
                <el-select
                  v-model="activity_form[`question_${number}`].type"
                  @change="activity_form[`question_${number}`].options = ['']"
                >
                  <el-option
                    v-for="{ label, value } in question_types"
                    :key="value"
                    :label="label"
                    :value="value"
                  ></el-option>
                </el-select>
              </div>
              <div style="margin-bottom: 12px; display: flex">
                <span class="question-title">問題</span>
                <el-input
                  v-model="activity_form[`question_${number}`].question"
                ></el-input>
              </div>
              <div style="display: flex">
                <span class="question-title">選項</span>
                <div style="margin-bottom: 12px">
                  <div
                    v-for="(_, index) in activity_form[`question_${number}`]
                      .options"
                    :key="index"
                    class="question-option"
                  >
                    <el-input
                      :disabled="
                        activity_form[`question_${number}`].type === 'SIMPLE'
                      "
                      v-model="
                        activity_form[`question_${number}`].options[index]
                      "
                    ></el-input>
                    <div style="display: flex; align-items: center">
                      <el-button
                        label="新增"
                        type="primary"
                        size="mini"
                        :disabled="
                          activity_form[`question_${number}`].type === 'SIMPLE'
                        "
                        circle
                        @click="handleAddQuestion(`question_${number}`, index)"
                      >
                        <i class="el-icon-plus"></i>
                      </el-button>
                      <el-button
                        icon="el-icon-minus"
                        label="移除"
                        type="danger"
                        size="mini"
                        :disabled="
                          activity_form[`question_${number}`].type === 'SIMPLE'
                        "
                        circle
                        @click="
                          handleDeleteQuestion(`question_${number}`, index)
                        "
                      ></el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
        <el-form-item label="活動聯絡人信箱" prop="contact_email">
          <el-input
            v-model="activity_form.contact_email"
            maxlength="256"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="承辦人備註" prop="comment">
          <div ref="js-editor-comment" class="editor"></div>
        </el-form-item>
        <el-form-item label="線上模式" prop="live">
          <el-radio-group v-model="activity_form.live" size="small">
            <el-radio :label="true" :border="true">是</el-radio>
            <el-radio :label="false" :border="true">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="網址連結" prop="live_stream_url">
          <el-input v-model="activity_form.live_stream_url"></el-input>
        </el-form-item>
        <el-form-item label="報名連結" prop="registration_url">
          <el-input v-model="activity_form.registration_url"></el-input>
        </el-form-item>
        <el-form-item label="成果網站" prop="final_project_url">
          <el-input v-model="activity_form.final_project_url"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getActivity, updateActivity } from "@/api/activity";
import { getActivityCategories } from "@/api/activity-category";
import E from "wangeditor";
import dayjs from "dayjs";

const IDX_ARRAY = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];

export default {
  name: "EditActivity",
  data() {
    const validateCategory = (_rule, _value, callback) => {
      if (
        this.subcategories.length !== 0 &&
        this.subcategories.findIndex(
          ({ subcategory_id }) =>
            subcategory_id === this.activity_form.subcategory_id
        ) === -1
      ) {
        callback(new Error("請輸入正確次種類"));
      }

      callback();
    };

    const validateStartDate = (_rule, _value, callback) => {
      if (
        dayjs(this.activity_form.start_date).isAfter(
          this.activity_form.end_date
        )
      ) {
        callback(new Error("起始時間不可大於結束時間"));
      }
      callback();
    };

    const validateEndDate = (_rule, _value, callback) => {
      if (
        dayjs(this.activity_form.start_date).isAfter(
          this.activity_form.end_date
        )
      ) {
        callback(new Error("結束時間不可小於起始時間"));
      }
      callback();
    };

    const validateActivitySessions = (_rule, _value, callback) => {
      if (this.activity_form.activity_sessions.length === 0) {
        callback("請輸入場次");
      }

      if (
        !this.activity_form.activity_sessions.some(({ start_time, end_time }) =>
          dayjs(start_time).isSame(end_time, "day")
        )
      ) {
        callback(new Error("請輸入正確活動時段"));
      }

      if (
        this.activity_form.activity_sessions.some(
          ({ start_time, end_time }) =>
            dayjs(start_time).isAfter(end_time) ||
            dayjs(start_time).isSame(end_time)
        )
      ) {
        callback(new Error("請輸入正確活動時段"));
      }

      callback();
    };

    return {
      activity_form: {
        photo: null,
        start_date: "",
        end_date: "",
        title_zh: "",
        title_en: "",
        introduction_zh: "",
        introduction_en: "",
        location_zh: "",
        location_en: "",
        category_id: "",
        subcategory_id: "",
        max_participant: 0,
        live: false,
        live_stream_url: "",
        registration_url: "",
        final_project_url: "",
        question_1: {
          required: true,
          question: "",
          type: "SIMPLE",
          options: [""],
        },
        question_2: {
          required: true,
          question: "",
          type: "SIMPLE",
          options: [""],
        },
        question_3: {
          required: true,
          question: "",
          type: "SIMPLE",
          options: [""],
        },
        contact_email: "",
        comment: "",
        published: false,
        activity_sessions: [{ start_time: null, end_time: null }],
      },
      activity_rules: {
        photo: [{ required: true, message: "請選擇照片", trigger: "blur" }],
        start_date: [
          { required: true, message: "請輸入起始時間", trigger: "blur" },
          { validator: validateStartDate, trigger: "change" },
        ],
        end_date: [
          { required: true, message: "請輸入結束時間", trigger: "blur" },
          { validator: validateEndDate, trigger: "change" },
        ],
        title_zh: [
          { required: true, message: "請輸入標題", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        title_en: [
          { required: true, message: "請輸入標題", trigger: "blur" },
          { max: 178, message: "長度過長", trigger: "blur" },
        ],
        introduction_zh: [
          { required: true, message: "請輸入介紹", trigger: "blur" },
        ],
        introduction_en: [
          { required: true, message: "請輸入介紹", trigger: "blur" },
        ],
        live: [
          { required: true, message: "請輸入線上模式", trigger: "change" },
        ],
        location_zh: [
          { required: true, message: "請輸入地點", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        location_en: [
          { required: true, message: "請輸入地點", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        category_id: [
          { required: true, message: "請輸入類別", trigger: "change" },
          { validator: validateCategory, trigger: "change" },
        ],
        max_participant: [{ required: true, message: "請輸入最大參與人數" }],
        contact_email: [{ max: 256, message: "長度過長", trigger: "blur" }],
        activity_sessions: [
          { required: true, message: "請輸入活動時段", trigger: "change" },
          { validator: validateActivitySessions, trigger: "change" },
        ],
      },
      preview_url: "",
      categories: [],
      subcategories: [],
      idx_array: IDX_ARRAY,
      question_types: [
        { label: "簡述", value: "SIMPLE" },
        { label: "單選", value: "SINGLE_CHOICE" },
        { label: "多選", value: "MULTIPLE_CHOICE" },
      ],
    };
  },
  computed: {
    activityId() {
      return this.$route.params.id;
    },
  },
  watch: {
    "activity_form.category_id": {
      handler: function (n) {
        const current = this.categories.find(
          ({ category_id }) => category_id === n
        );

        if (current && "subcategories" in current) {
          this.subcategories = current.subcategories;
        }

        if (
          this.subcategories.length !== 0 &&
          this.activity_form.subcategory_id === ""
        ) {
          this.activity_form.subcategory_id =
            current.subcategories[0].subcategory_id;
        }
      },
    },
  },
  mounted() {
    this.handleGetCategories();
  },
  methods: {
    async handleGetCategories() {
      this.categories = await getActivityCategories();

      if (this.categories.length === 0) {
        await this.$alert("你尚未有任何活動類別，系統將幫你自動跳轉", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.$router.push({ name: "Category" });
          },
        });
      }

      if ("category_id" in this.categories[0]) {
        this.activity_form.category_id = this.categories[0].category_id;
      }

      await this.handleGetActivity();
      this.handleInitEditor();
    },
    async handleGetActivity() {
      try {
        const res = await getActivity(this.activityId, { relation: true });

        Object.keys(this.activity_form).forEach((key) => {
          if (key in res) {
            if (key === "activity_sessions" && res[key].length === 0) return;
            this.activity_form[key] = res[key];
          }
        });

        // check subcategory is not null, if not null update activity_form subcategory_id
        if (res.subcategory) {
          this.activity_form.subcategory_id = res.subcategory.subcategory_id;
        }

        this.activity_form.category_id = res.category.category_id;

        this.preview_url = `${process.env.VUE_APP_IMAGE_API}`.endsWith("/")
          ? `${process.env.VUE_APP_IMAGE_API}${this.activity_form.photo}`
          : `${process.env.VUE_APP_IMAGE_API}/${this.activity_form.photo}`;
      } catch (e) {
        await this.$alert("輸入資料有誤，系統將強制跳轉頁面", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.handleRedirect();
          },
        });
      }
    },
    handleInitEditor() {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith("js-editor")) {
          const editor = new E(this.$refs[ref]);
          const key = ref.replace("js-editor-", "");

          editor.config.onchange = (html) => {
            this.activity_form[key] = html;
          };

          // using base64 to store image
          editor.config.uploadImgShowBase64 = true;
          // disable online image url
          editor.config.showLinkImg = false;
          // change language to en
          editor.config.lang = "en";

          // import i18n
          editor.i18next = window.i18next;

          editor.config.placeholder = "請輸入內容";
          editor.config.menus = [
            "fontSize",
            "bold",
            "head",
            "link",
            "italic",
            "strikeThrough",
            "underline",
            "image",
          ];

          editor.create();

          editor.txt.html(this.activity_form[key]);
        }
      });
    },
    handleDeleteActivitySession(index) {
      if (this.activity_form.activity_sessions.length === 1) {
        this.$message.error("至少要有一個時段");
        return;
      }

      this.activity_form.activity_sessions.splice(index, 1);
    },
    handleAddActivitySession(index) {
      if (this.activity_form.activity_sessions.length === 10) {
        this.$message.error("最多只能新增10個時段");
        return;
      }

      this.activity_form.activity_sessions.splice(index + 1, 0, {
        start_time: "",
        end_time: "",
      });
    },
    handleDeleteQuestion(key, index) {
      if (this.activity_form[key].options.length === 1) {
        this.$message.error("至少要有一個問題");
        return;
      }

      this.activity_form[key].options.splice(index, 1);
    },
    handleAddQuestion(key, index) {
      if (this.activity_form[key].options.length === 10) {
        this.$message.error("最多只能新增10個問題");
        return;
      }

      this.activity_form[key].options.splice(index + 1, 0, "");
    },
    handleBeforeUpload(file) {
      const imageType = ["image/jpeg", "image/jpg", "image/png"];

      if (imageType.indexOf(file.type) === -1) {
        this.$message.error("請上傳jpg, jpeg, png的格式");
        return false;
      }

      return true;
    },
    handleUpload(file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.preview_url = reader.result;
      });

      if (file) {
        reader.readAsDataURL(file.file);
        this.activity_form.photo = file.file;
      }
    },
    handleSubmit() {
      this.$refs.activityForm.validate(async (valid) => {
        if (valid) {
          const formData = new FormData();

          Object.keys(this.activity_form).forEach((key) => {
            if (key === "photo") {
              if (this.activity_form[key] instanceof File) {
                formData.append("photo_file", this.activity_form[key]);
                return;
              }
              return;
            }

            if (key === "start_date" || key === "end_date") {
              // transform date to ISOString
              formData.append(
                key,
                dayjs(this.activity_form[key]).toISOString()
              );
              return;
            }

            if (key === "activity_sessions") {
              formData.append(
                "activity_sessions",
                JSON.stringify(this.activity_form[key])
              );
              return;
            }

            if (key.startsWith("question")) {
              formData.append(key, JSON.stringify(this.activity_form[key]));
              return;
            }

            formData.append(key, this.activity_form[key]);
          });

          if (this.subcategories.length === 0) {
            formData.delete("subcategory_id");
          }

          await updateActivity(this.activityId, formData);

          this.$message({
            type: "success",
            message: "編輯成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({
        name: "Activity",
        params: { id: this.activityId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-activity-block {
  ::v-deep.photo-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }

  .editor {
    position: relative;
    z-index: 1000;
  }

  .activity-sessions-block {
    .time-picker {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }
  }

  .question-title {
    margin-right: 12px;
    color: #606266;
    display: block;
    width: 30px;
  }

  .question-option {
    display: flex;
    width: 400px;
    gap: 8px;
    margin-bottom: 12px;
  }

  ::v-deep.photo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1200px;
    height: 360px;
    line-height: 360px;
    text-align: center;
  }

  ::v-deep.photo {
    width: auto;
    height: 360px;
    display: block;
  }
}
</style>
